import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GthUserModel } from '@sentinels/models';

import { CurrentState } from '../../state';
import { AuthState } from './state';

export const selectAuthState = createFeatureSelector<AuthState>('authFeature');

export const selectAuthLoading = createSelector(
  selectAuthState,
  (state) => state.state === CurrentState.Loading,
);

export const selectUser = createSelector(
  selectAuthState,
  (state) => {
    return state?.user;
  },
);

export const selectUserOrGuest = (guest: GthUserModel)=> createSelector(
  selectAuthState,
  (state) => {
    return state?.user || guest;
  },
);
